import React, { useState, useContext, useMemo, useEffect, useCallback } from "react";
import { Grid, TextField, Card, CardContent, Typography, /*Stack,*/ Button, } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import ImportExportIcon from "@mui/icons-material/ImportExport";
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import OrderByDate from "./OrderByDate";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { setJobs, setServiceTime } from "../../../src/store/actions/RouteAction";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { JobContext } from '../../screens/Routing/jobContext';
import DialogAlertMain from "components/common/DialogAlertMain";
import axiosCall from "axios";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  detailWidth: {
    width: "20rem", height: "100%", marginLeft: "-2rem",
    [theme.breakpoints.down("md")]: { width: "30rem", marginLeft: 0, },
    [theme.breakpoints.down("sm")]: { width: "30rem", marginLeft: 0, },
    [theme.breakpoints.down("xs")]: { width: "100%", marginLeft: 0, },
  },
}));
const timeSetter = (d, date) => {
  const hour = d?.split(":")[0];
  const minutes = d?.split(":")[1];
  if (hour === "24" || hour === "00") { return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, minutes); }
  else { return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minutes); }
};
const timeSplitter = (timeFrame, date) => {
  if (timeFrame === undefined) return { start_time: '', end_time: '' };
  const getHours = (val) => moment(val, ["h:mm A"]).format("HH:mm");
  const start = getHours(timeFrame?.split(" - ")[0]);
  let end;
  // if (eta) { end = start; }
  // else { end = getHours(timeFrame?.split(" - ")[1]); }

  //newValue
  end = getHours(timeFrame?.split(" - ")[1]);
  return {
    start_time: timeSetter(start, date),
    end_time: timeSetter(moment(end, ["HH:mm"]).format("HH:mm"), date)
  }
};
const convertTime = (time) => new Date(time).toLocaleString('en-US', { timeStyle: "short", hour12: true });
export default function Header({ value, setValue, refresh }) {
  const serviceTime = useSelector((state) => state.route.serviceTime) ?? sessionStorage.getItem("serviceTime");
  const axios = useAxiosPrivate()
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let jobs = useSelector((state) => state.route.jobs);
  const classes = useStyles();
  const { newJob, setNewJob, setVehicles, setShared, shared, checkDispatchButton, setCheckDispatchButton } = useContext(JobContext);
  const [open, setOpen] = useState(false);
  //plan Summary
  const [costCal, setCostCal] = useState(null)
  const totalCost = costCal?.reduce((accumulator, object) => { return accumulator + object?.cost; }, 0);
  const [estimation, loadingEstimation] = useState(false)
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [jobCreation, setJobCreation] = useState(false);
  const [workingTime, setWorkingTime] = useState(0);
  const [drivingTime, setDrivingTime] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [jobsCount, setJobsCount] = useState(0);
  const [isRouteBuildLoading, setIsRouteBuildLoading] = useState(false);


  const dateString = useMemo(() => {
    // convert to yyyy-mm-dd
    return value?.toISOString().split("T")[0];
  }, [value])


  const loadOrders = async () => {
    try {
      const { data } = await axios.get(`/job/orders-date-timeline/${dateString}`);
      if (data?.estimation?.length > 0) {
        setCostCal(data?.estimation);
        setJobsCount(data?.jobs?.length);
      }

      console.log("data", data);

      const initialJobs = [];
      const dispatched = data?.dispatch;
      setShared(dispatched);
      setCheckDispatchButton(data?.dispatchableButton);
      if (data?.tempVehicles?.length > 0) { setVehicles(data?.tempVehicles); }
      for (const order of data?.jobs) {
        const { start_time, end_time } = timeSplitter(order?.timeFrame, value);
        const object = {
          customerName: order?.customerName,
          timeFrame: order?.timeFrame,
          timeSlot: dispatched ? (order.orderID[0].pickupTimeSlot ? order?.orderID[0]?.pickupTimeSlot : order?.orderID[0]?.diliverTimeSlot) : "",
          driver: dispatched ? order.driverID[0].name : "",
          vehicle: dispatched ? order.vehicleID[0].name : "",
          type: order?.type,
          address: dispatched ? order?.orderID[0]?.orderAddress : order?.address,
          id: dispatched ? order?.id : order?.orderID, // this is mongoDB id
          group: dispatched ? order?.vehicleId : 'unassigned',
          title: dispatched ? "" : order?.orderTitle, // this is readable ID (removed dispatched value order?.orderID[0]?.orderId) ,
          mapOrderId: dispatched ? order?.orderID[0]?.orderId : order?.orderTitle, canMove: dispatched ? false : true,
          canResize: false, lat: dispatched ? order?.orderID[0]?.orderLocation?.lat : order?.location?.lat,
          lng: dispatched ? order?.orderID[0]?.orderLocation?.lng : order?.location?.lng, itemProps: {
            'data-custom-attribute': dispatched ? order?.orderID[0]?.orderId : order?.orderTitle, 'aria-hidden': true,
            style: { background: dispatched ? order?.vehicleID[0]?.color : '#000000', outline: '0.1px solid white', }
          }, start_time, end_time,
        };
        initialJobs.push(object);
      }
      dispatch(setJobs()); // zooooom
      setNewJob(initialJobs);
      // eslint-disable-next-line 
    } catch (error) {
      // set message for this
      // eslint-disable-next-line 
      console.log({ error });
    }
  };

  const handleExportCSV = () => {
    // Prepare CSV headers and data rows
    const csvData = [
      "Vehicle, Driver, Order ID, Order Type, Customer Name, Customer Address, Scheduled Time Slot, Time Window (ETA)"
    ];

    // Group jobs by vehicle
    const jobsByVehicle = newJob.reduce((acc, job) => {
      if (!acc[job.vehicle]) {
        acc[job.vehicle] = [];
      }
      acc[job.vehicle].push(job);
      return acc;
    }, {});

    // Sort jobs within each vehicle group by start_time (earliest to latest)
    Object.keys(jobsByVehicle).forEach(vehicle => {
      jobsByVehicle[vehicle].sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
    });

    // Flatten the grouped and sorted jobs into csvData
    Object.values(jobsByVehicle).forEach(jobs => {
      jobs.forEach(job => {
        const row = [
          job.vehicle,                        // Vehicle
          job.driver,                         // Driver
          job.mapOrderId,                     // Order ID
          job.type === "COLLECTION" ? "Pickup" : "Delivery",  // Order Type
          `"${job.customerName}"`,            // Customer Name (quote to prevent splitting if name has commas)
          `"${job.address}"`,                 // Customer Address (escaped with quotes to prevent splitting)
          job.timeSlot,                       // Scheduled Time Slot
          job.timeFrame                       // Time Window (ETA)
        ].join(",");  // Join the row with commas

        csvData.push(row);  // Add row to csvData
      });
    });

    // Convert csvData array into a single CSV string
    const csvContent = "data:text/csv;charset=utf-8," + csvData.join("\n");

    // Create a downloadable link for the CSV
    const csvLink = document.createElement('a');
    csvLink.href = encodeURI(csvContent);
    csvLink.setAttribute('download', `dispatched_routes_${dateString}.csv`);
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
  };

  useEffect(() => {
    (async () => { await loadOrders() })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])


  useEffect(() => {
    const totalCal = (cost) => {
      let work = 0;
      let drive = 0;
      let distance = 0;
      for (let i = 0; i < cost.length; i++) {
        work = work + cost[i].serviceTime;
        drive = drive + cost[i].drivingTime;
        distance = distance + cost[i].distance;
      }
      setWorkingTime(work);
      setDrivingTime(drive);
      setTotalDistance(distance);
    }

    if (costCal) {
      totalCal(costCal);
    }
  }, [costCal])

  const dispatchOrders = async () => {
    try {
      setNewJob([]);
      setSaving(true);
      if (jobs?.length < 1) return;
      const check = jobs.find(e => e.vehicleID === 'unassigned');
      if (check) {
        setAlert({ showAlert: true, severity: "error", message: "Please map all the Jobs" });
        return;
      }
      let getEstimation = await getEstimationRoute();
      setCostCal(getEstimation?.data);
      console.log(jobs);

      const { data } = await axios.post(`/job/${dateString}`, { job: jobs, estimation: getEstimation?.data });
      setSaving(false);
      setAlert({ showAlert: true, severity: "success", message: data?.message });
      setShared(false);
      dispatch(setJobs());
    } catch (err) {
      // eslint-disable-next-line
      console.log({ err });
      setAlert({ showAlert: true, severity: "error", message: "The job dispatch failed. Please try again later." });
    } finally {
      (async () => { await loadOrders() })();
      setSaving(false);
    }
  };

  const getGoogleRouteBuild = async (access_token, dataObj) => {

    //const Create_url = "https://cloudoptimization.googleapis.com/v1/projects/vrp-solution-370904:optimizeTours";
    const url = 'https://routeoptimization.googleapis.com/v1/projects/vrp-solution-370904:optimizeTours';
    var config3 = {
      method: 'post',
      url: url,
      headers: { 'Authorization': 'Bearer ' + access_token, 'x-goog-user-project': 'vrp-solution-370904', 'Content-Type': 'application/json; charset=utf-8' },
      data: dataObj
    };
    const { data } = await axiosCall(config3);

    return data;
  }

  const generateRoutes = async () => {
    setCostCal(null)
    try {
      setLoading(true);
      setNewJob([]);
      dispatch(setJobs());

      const payloadForRoutes = await axios.get(`/job/generate-route-day-1/${dateString}`);

      const googleRouteData = await getGoogleRouteBuild(payloadForRoutes?.data?.token, payloadForRoutes?.data?.requestDataModel)

      const { data } = await axios.post(`/job/generate-route-day-2/${dateString}`, { ...googleRouteData, shipmentsArray: payloadForRoutes?.data?.requestDataModel?.model?.shipments });

      if (payloadForRoutes?.data?.nextRequestPayload?.resSendVehicleDetails?.length > 0) { setVehicles(payloadForRoutes?.data?.nextRequestPayload?.resSendVehicleDetails); }
      // sessionStorage.setItem('serviceTime', data?.routes[0]?.jobs[0]?.service_time); 
      const newJobs = [];
      // const vehicleDetails = [];
      const finalJobs = [];
      data?.routes?.forEach((route) => {
        // vehicleDetails.push({ vehicleID: route.vehicleID });
        const driverID = route.driverID
        route?.jobs?.forEach((job) => { newJobs.push({ ...job, driverID: driverID }); });
      });
      newJobs?.forEach((job) => {
        let obj = {};
        payloadForRoutes?.data?.nextRequestPayload?.resSendVehicleDetails?.forEach((vehicle) => {
          if (vehicle.id === job?.vehicleID) {
            const { start_time, end_time } = timeSplitter(job?.timeFrame, value, vehicle?.routeSettings?.speed, true)
            obj = {
              ...job, service_time: vehicle?.routeSettings?.speed, vehicle: vehicle, driverID: vehicle?.driver?._id,
              start_time, end_time, timeFrame: `${convertTime(start_time)?.toString()} - ${convertTime(end_time)?.toString()}`
            };
          }
        });
        finalJobs.push(obj);
      });
      data?.dropped?.forEach((job) => {
        const order = payloadForRoutes?.data?.nextRequestPayload?.jobs?.find(e => e.id === job.id);
        finalJobs.push({ ...job, orderID: order?.orderID, vehicleID: 'unassigned' });
      });
      data?.newJobsArray?.forEach((job) => { finalJobs.push({ ...job, vehicleID: 'unassigned' }); });

      dispatch(setJobs(finalJobs));
      setAlert({ showAlert: true, severity: "success", message: "Route build successfully!", });
      setLoading(false);
      setIsRouteBuildLoading(false)
    } catch (err) {
      (async () => { await loadOrders() })();
      // eslint-disable-next-line 
      setAlert({ showAlert: true, severity: "error", message: err?.response?.data?.message || "Something went wrong!", });
    } finally { setLoading(false); }
  };

  const getEstimation = async () => {
    loadingEstimation(true)
    const check = jobs.find(e => e.vehicleID === 'unassigned');
    if (check) {
      setAlert({ showAlert: true, severity: "error", message: "Please map all the Jobs" });
      loadingEstimation(false)
      return;
    } else {
      try {
        const { data } = await getEstimationRoute();
        setCostCal(data);
        loadingEstimation(false)
        setAlert({ showAlert: true, severity: "success", message: "Estimation generated", });
        setOpen(false);
        // message
      } catch (error) {
        loadingEstimation(false)
        setAlert({ showAlert: true, severity: "error", message: error?.response?.data ?? "Can't get Estimation", });
        setOpen(false);
      }
    }
  }

  const getEstimationRoute = async () => {
    const payloadDate = [];
    jobs.forEach((job) => { // IF VEHICLE ALREADY EXISTS
      const idx = payloadDate.findIndex((e) => e?.vehicleId === job?.vehicleID);
      if (idx >= 0) { // IF YES, ADD ORDER TO ORDERS ARRAY
        payloadDate[idx].orders.push({
          orderId: job?.orderID[0]?._id, oID: job?.orderID[0]?.orderId, lat: job?.orderID[0]?.orderLocation?.lat,
          lng: job?.orderID[0]?.orderLocation?.lng, timeFrame: job?.timeFrame,
        });
      } else { // ELSE CREATE A OBJECT WITH ORDER
        payloadDate.push({
          vehicleId: job?.vehicleID,
          orders: [{
            orderId: job?.orderID[0]?._id, oID: job?.orderID[0]?.orderId, lat: job?.orderID[0]?.orderLocation?.lat,
            lng: job?.orderID[0]?.orderLocation?.lng, timeFrame: job?.timeFrame,
          }],
        });
      }
    })
    const result = await axios.post(`/cost/estimation`, payloadDate);
    return result;
  }

  const createJobs = async () => {
    try {
      setNewJob([]);
      dispatch(setJobs());
      setJobCreation(true);
      setShared(false);
      // eslint-disable-next-line
      const { data } = await axios.get(`/job/generate-jobs/${dateString}`);
      console.log("hi", data);

      // eslint-disable-next-line
      setOpen(false);
      return true;
      // message
    } catch (error) {
      setJobCreation(false);
      setAlert({ showAlert: true, severity: "error", message: "No orders were found", });
    } finally { setJobCreation(false); (async () => { await loadOrders() })(); }
  };

  const handleClickOpenBuildRoute = async () => {

    setIsRouteBuildLoading(true);
    await createJobs().then((res) => {

      if (res) {
        setTimeout(() => {
          generateRoutes();
        }, 4000);
      } else {
        setIsRouteBuildLoading(false);
      }
    });
  };

  const [hasMounted, setHasMounted] = useState(false);

  // useEffect(() => {
  //   if (hasMounted) {
  //     // Only call handleClickOpenBuildRoute if the component has mounted before
  //     handleClickOpenBuildRoute();
  //   } else {
  //     // Set the mounted flag to true after the initial render
  //     setHasMounted(true);
  //   }
  // }, [refresh]);

  useEffect(() => {
    if (hasMounted) {
      // Clear the jobs, routes, and related states without rebuilding the routes
      setNewJob([]);      // Clear job list
      dispatch(setJobs()); // Reset the Redux state for jobs
      setCostCal(null);    // Clear cost estimation data
      setJobsCount(0);     // Reset jobs count
      setWorkingTime(0);   // Reset working time
      setDrivingTime(0);   // Reset driving time
      setTotalDistance(0); // Reset total distance
      setShared(false);    // Reset the shared state
    } else {
      // Set the mounted flag to true after the initial render
      setHasMounted(true);
    }
  }, [refresh]);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const navigateToOrders = () => {
    setNewJob([]);
    dispatch(setJobs());
    navigate('/custom-orders');
  }

  const estimationBtnDisabled = useMemo(() => {
    if (jobs) {
      const isJobsAssigned = jobs?.find(e => e?.vehicleID === 'unassigned')
      return (isJobsAssigned)
    } else {
      return true
    }
  }, [jobs])

  const getServiceTime = useCallback(async () => {
    try {
      const { data } = await axios.get("/servicetime");
      if (data?.serviceTime) {
        sessionStorage.setItem("serviceTime", data?.serviceTime);
        dispatch(setServiceTime(data?.serviceTime));
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: err.response.data.message,
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "",
          });
        }, 3000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Service time not set!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "error",
            message: "Service time not set!",
          });
        }, 3000);
      }
    }
    //eslint-disable-next-line
  }, [serviceTime]);

  useEffect(() => {
    getServiceTime();
    //eslint-disable-next-line
  }, []);

  const clearEstimationData = () => {
    setCostCal(null);
    setJobsCount(0);
    setWorkingTime(0);
    setDrivingTime(0);
    setTotalDistance(0);
  }

  const formatTime = (totalMinutes) => {
    const hours = Math.floor(Number(totalMinutes) / 60);
    const minutes = Math.floor(Number(totalMinutes) % 60);
    const formattedTime = `${hours} h ${minutes} min`;
    return formattedTime;
  }

  console.log("newJob", newJob);


  return (
    <Card elevation={0} className={classes.detailWidth} > <CardContent>
      <Grid container pt={2} style={{ width: "100%", borderColor: "black" }} >
        <DialogAlertMain alert={alert} setAlert={setAlert} />
        <Grid container item alignItems="center" justifyContent="center">
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <TextField
              label={`Date`}
              value={dateString}
              fullWidth
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              onChange={(newValue) => {
                setNewJob([]);
                setValue(new Date(newValue.target.value));
                clearEstimationData();
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
            <Grid container direction="row" spacing={{ lg: 2, md: 2, sm: 2, xs: 1, }} >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Button startIcon={<AddIcon />}
                  onClick={navigateToOrders} disableElevation variant="contained"
                  sx={{
                    fontSize: { lg: "0.95rem", md: "1rem", sm: "1rem", xs: "0.95rem", },
                    fontWeight: "bold", textTransform: 'capitalize', width: "100%", height: "4rem",
                    backgroundColor: "white", color: "black", borderBottom: "0.1px", borderColor: "#bbbbbb",
                    borderStyle: "solid", justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center", },
                    "&:hover": { backgroundColor: "#f8f8f8", boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)", transition: "all 0.3s ease 0s" },
                  }} > Add Orders </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={"auto"} sm={"auto"} xs={"auto"} mt={3}>
            <Typography sx={{ textAlign: "left", fontSize: "0.94rem", fontWeight: "400", }}> Optimization </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} mt={2}>
            <LoadingButton loading={loading || isRouteBuildLoading} disabled={checkDispatchButton} onClick={handleClickOpenBuildRoute}
              startIcon={<BoltOutlinedIcon sx={{ color: "#FFAE00", }} />}
              disableElevation variant="contained"
              sx={{
                fontSize: { lg: "0.95rem", md: "1rem", sm: "1rem", xs: "0.95rem", },
                fontWeight: "bold", textTransform: 'capitalize', width: "100%", height: "4rem",
                backgroundColor: "white", color: "black",
                justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center", },
                borderBottom: "0.1px", borderColor: "#bbbbbb", borderStyle: "solid",
                "&:hover": { backgroundColor: "#f8f8f8", boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)", transition: "all 0.3s ease 0s" },
              }} > Build Routes </LoadingButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
            <LoadingButton startIcon={<DeviceHubOutlinedIcon sx={{ color: "#D36137", }} />} loading={saving} disabled={newJob?.length > 1 || loading || shared || checkDispatchButton}
              onClick={dispatchOrders} disableElevation variant="contained"
              sx={{
                fontSize: { lg: "0.95rem", md: "1rem", sm: "1rem", xs: "0.95rem", },
                fontWeight: "bold", textTransform: 'capitalize', width: "100%",
                height: "4rem", justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center", },
                backgroundColor: "white", color: "black", borderBottom: "0.1px", borderColor: "#bbbbbb", borderStyle: "solid",
                "&:hover": { backgroundColor: "#f8f8f8", boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)", transition: "all 0.3s ease 0s" },
              }} > Dispatch routes </LoadingButton>
          </Grid>
          <Grid item lg={12} md={"auto"} sm={"auto"} xs={"auto"} mt={3}>
            <Typography sx={{ textAlign: "left", fontSize: "0.94rem", fontWeight: "400", }}> Plan Summary </Typography>
          </Grid>
          {costCal?.length > 0 && <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.3rem" }}>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left", fontWeight: "bold" }}>Order Count:</Typography>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left" }}>{jobs?.length ? jobs?.length : jobsCount}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.3rem" }}>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left", fontWeight: "bold" }}>Vehicles:</Typography>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left" }}>{costCal?.length}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.3rem" }}>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left", fontWeight: "bold" }}>Total Cost:</Typography>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left" }}>${totalCost?.toFixed(2)}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.3rem" }}>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left", fontWeight: "bold" }}>Driving Time:</Typography>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left" }}>{formatTime(drivingTime)}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.3rem" }}>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left", fontWeight: "bold" }}>Working Time:</Typography>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left" }}>{formatTime(workingTime)}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.3rem" }}>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left", fontWeight: "bold" }}>Distance:</Typography>
              <Typography sx={{ fontSize: "1.3rem", textAlign: "left" }}>{totalDistance?.toFixed(1)} mi</Typography>
            </div>
          </Grid>}
          <Grid item xs={12} md={12} lg={12} mt={2}>
            <LoadingButton loading={estimation} disabled={newJob?.length > 1 || loading || shared || estimationBtnDisabled}
              onClick={getEstimation} startIcon={<AutoGraphOutlinedIcon sx={{ color: "green", }} />}
              disableElevation variant="contained"
              sx={{
                fontSize: { lg: "0.95rem", md: "1rem", sm: "1rem", xs: "0.95rem", }, fontWeight: "bold", textTransform: 'capitalize', width: "100%", height: "4rem",
                backgroundColor: "white", color: "black", borderBottom: "0.1px", justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center", },
                borderColor: "#bbbbbb", borderStyle: "solid",
                "&:hover": { backgroundColor: "#f8f8f8", boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)", transition: "all 0.3s ease 0s" },
              }} > Get Estimation </LoadingButton>
          </Grid>
          {/* Export Dispatched Routes Button */}
          <Grid item xs={12} md={12} lg={12} mt={2}>
            <Button
              disabled={newJob?.length === 0} // Enable only if routes are dispatched
              startIcon={<FileDownloadOutlinedIcon sx={{ color: "#3f51b5", }} />}
              variant="contained"
              onClick={handleExportCSV}  // Trigger CSV export on click
              disableElevation
              sx={{
                fontSize: { lg: "0.95rem", md: "1rem", sm: "1rem", xs: "0.95rem", }, fontWeight: "bold", textTransform: 'capitalize', width: "100%", height: "4rem",
                backgroundColor: "white", color: "black", borderBottom: "0.1px", justifyContent: { lg: "flex-start", md: "center", sm: "center", xs: "center", },
                borderColor: "#bbbbbb", borderStyle: "solid",
                "&:hover": { backgroundColor: "#f8f8f8", boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)", transition: "all 0.3s ease 0s" }
              }}
            >
              {/* <CSVLink data={csvData} filename={`dispatched_routes_${new Date().toISOString().split("T")[0]}.csv`} style={{ textDecoration: 'none', color: 'inherit' }}> */}
              Export Dispatched Routes
              {/* </CSVLink> */}
            </Button>
          </Grid>

        </Grid> </Grid>
    </CardContent>
      <OrderByDate {...{ setOpen, open, date: value, createJobs: async () => await createJobs(), loading: jobCreation }} />
    </Card>
  );
}
